import React from "react"
import { navigate } from "gatsby"
import {Breadcrumb} from "react-bootstrap"

export default function Breadcrumbs(props) {
  const lstLinks = []
  
  if (props.links != null) {
    const links = JSON.parse(props.links)     
    for (var x = 0; x < links.length; x++) {
        let url = links[x].link
        lstLinks.push(<Breadcrumb.Item key={"id" + x} active={x + 1 == links.length} onClick={() => navigate(url)}>{links[x].title}</Breadcrumb.Item>)
      }
  }

  return (<Breadcrumb className="card breadcrumb-card">{lstLinks}</Breadcrumb>)
}
