import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"
import ClassroomLayout from "../layout/classroom-layout"
import { RichTextRenderer } from "@webiny/react-rich-text-renderer"
import { Link } from "gatsby"
import { Card, Container, Row, Col, Button } from "react-bootstrap"
import Breadcrumbs from "../shared/breadcrumb"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowCircleRight,
  faClipboardList,
  faFolder,
} from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"
import { setContextSidebar } from "../../../redux/app-actions"

export default function Module(props) {
  const dispatch = useDispatch()

  const { pageContext } = props

  const { course, breadcrumb } = pageContext
  const { getModule, getCourse } = props.data.scocms

  const Course = getCourse.data
  const Module = getModule.data

  const lstTopics = []
  const moduleTest = Module?.test

  if (Module?.topics != null) {
    for (const [i, t] of Module.topics.entries()) {
      lstTopics.push(
        <li key={i}>
          <Link to={"/classroom/" + course + "/" + Module.slug + "/" + t.slug}>
            {t.name}
          </Link>
        </li>
      )
    }
  }

  useEffect(() => {
    dispatch(setContextSidebar({ course: Course, module: Module }))
  }, [dispatch])

  return (
    <ClassroomLayout>
      {Module ? (
        <>
          <section className="site-header">
            <Container>
              <Row>
                <Col xs="auto" className="d-flex align-items-center">
                  <FontAwesomeIcon fontSize={"50px"} icon={faFolder} />
                </Col>
                <Col>
                  <h1>{Module.name}</h1>
                  <RichTextRenderer data={Module.pageInstructions} />
                </Col>
              </Row>
            </Container>
          </section>
          <Container>
            <div className="mb-4">
              <Breadcrumbs links={breadcrumb} />
            </div>
            <Row>
              <Col xs="12" md="4">
                <Card className="mb-4">
                  <Card.Body>
                    <Card.Title>Learning Objective</Card.Title>
                    <RichTextRenderer data={Module.learningObjective} />
                  </Card.Body>
                </Card>

                {Module.youWillNeed ? (
                  <Card className="mb-4">
                    <Card.Body>
                      <Card.Title>You will need</Card.Title>
                    </Card.Body>
                  </Card>
                ) : (
                  ""
                )}
              </Col>
              <Col xs="12" md="8">
                <Card>
                  <Card.Body>
                    <Card.Title>Topics</Card.Title>
                    <ol>{lstTopics}</ol>
                  </Card.Body>
                </Card>
                {moduleTest ? (
                  <Card className="my-4">
                    <Card.Body>
                      <Row>
                        <Col className="d-flex align-items-center">
                          <Card.Title style={{ margin: 0 }}>
                            <FontAwesomeIcon
                              className="me-2"
                              icon={faClipboardList}
                            />
                            Test Your Knowledge
                          </Card.Title>
                        </Col>
                        <Col xs="auto">
                          <Button
                            onClick={() =>
                              navigate(
                                "/classroom/" +
                                  course +
                                  "/" +
                                  Module.slug +
                                  "/module-test"
                              )
                            }
                          >
                            Start
                            <FontAwesomeIcon
                              className="ms-2"
                              icon={faArrowCircleRight}
                            />
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        ""
      )}
    </ClassroomLayout>
  )
}

export const query = graphql`
  query ($id: ID, $cid: ID) {
    scocms {
      getCourse(where: { id: $cid }) {
        ...courseFragment
      }

      getModule(where: { id: $id }) {
        data {
          name
          learningObjective
          pageInstructions
          shortName
          slug
          topics {
            id
            slug
            name
          }
          test {
            id
            name
          }
        }
      }
    }
  }
`
